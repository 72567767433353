






import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class DarkModeButton extends Vue {
    logout(): void {
        localStorage.removeItem('vuex');
        window.location.href = '/';
    }
}
